import { Box, Checkbox, Divider, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import DataTable from "examples/Tables/DataTable";
import React, { useState, useEffect } from "react";

export function EditRole({
  handleSubmit,
  isDisabled,
  isFormModified,
  handleRolePermitList = [],
  handlePermiByRolList = [], // lista de permisos asociados al rol
  role,
  handleFieldChange,
  handleUpdatePermissions, // Prop para manejar la actualización de permisos desde el controlador
}) {
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  useEffect(() => {
    // Obtener una lista de permission_id desde handlePermiByRolList
    const permissionIdsByRole = handlePermiByRolList.map((permission) => permission.permission_id);

    // Filtrar los permisos que están en ambas listas y establecerlos como seleccionados
    const initialSelectedPermissions = handleRolePermitList
      .filter((permission) => permissionIdsByRole.includes(permission.id))
      .map((permission) => permission.id);

    setSelectedPermissions(initialSelectedPermissions);
  }, [handleRolePermitList, handlePermiByRolList]);

  const handlePermissionChange = (event, permissionId) => {
    const updatedPermissions = event.target.checked
      ? [...selectedPermissions, permissionId]
      : selectedPermissions.filter((id) => id !== permissionId);

    setSelectedPermissions(updatedPermissions);

    // Mostrar en consola el JSON con los permisos seleccionados
  };

  // Obtener todos los tipos de permisos únicos
  const allPermissionTypes = Array.from(
    new Set(handleRolePermitList.map((permission) => permission.name))
  );

  // Configuración de las columnas
  const columns = [
    { Header: "Recurso", accessor: "resource", align: "center" },
    ...allPermissionTypes.map((type) => ({
      Header: type,
      accessor: type,
      align: "center",
    })),
  ];

  // Configuración de las filas
  const rows = Object.entries(
    handleRolePermitList.reduce((acc, permission) => {
      const moduleName = permission.module;
      if (!acc[moduleName]) {
        acc[moduleName] = { resource: moduleName };
      }
      acc[moduleName][permission.name] = (
        <Checkbox
          checked={selectedPermissions.includes(permission.id)}
          onChange={(event) => handlePermissionChange(event, permission.id)}
        />
      );
      return acc;
    }, {})
  ).map(([module, data]) => data);

  return (
    <>
      <Divider variant="middle" />
      <form onSubmit={handleSubmit}>
        <Box>
          <MDBox
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <MDBox style={{ width: "50%" }}>
              <Typography
                id="modal-modal-title"
                component="h5"
                style={{ marginTop: "5px", width: "50%" }}
              >
                Ajustar rol
              </Typography>
              <MDBox
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "5px",
                  width: "100%",
                }}
              >
                <MDInput
                  value={role?.name}
                  onChange={(e) => handleFieldChange("name", e.target.value)}
                  style={{ flexGrow: 1 }}
                />
                <MDButton
                  type="submit"
                  disabled={!isFormModified}
                  color="success"
                  style={{ marginLeft: "10px" }}
                >
                  Guardar
                </MDButton>
              </MDBox>
            </MDBox>
          </MDBox>
        </Box>
        <Divider variant="middle" />
      </form>

      <DataTable
        table={{ columns, rows }}
        canSearch={false}
        isSorted={false}
        entriesPerPage={false}
        showTotalEntries={false}
      />
      {/* Botón para actualizar los permisos */}
      <MDBox
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "20px",
        }}
      >
        <MDButton onClick={() => handleUpdatePermissions(selectedPermissions)} color="success">
          Actualizar permisos
        </MDButton>
      </MDBox>
    </>
  );
}
