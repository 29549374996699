import React, { useState } from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;

  // Estados para el tipo de gráfico de cada gráfica
  const [activitiesChartType, setActivitiesChartType] = useState("bar");
  const [evidencesChartType, setEvidencesChartType] = useState("line");
  const [participantsChartType, setParticipantsChartType] = useState("line");

  const currentDate = new Date();
  const lastSemesterDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 6);
  const lastSemesterDateString = lastSemesterDate.toLocaleDateString();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Total de participantes"
                count={281}
                percentage={{
                  color: "success",
                  amount: "",
                  label: "último semestre",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Total de actividades"
                count="2,300"
                percentage={{
                  color: "success",
                  amount: "",
                  label: "último semestre",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Total de evidencias"
                count="34k"
                percentage={{
                  color: "success",
                  amount: "",
                  label: "último semestre",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Actividades certificadas"
                count="+91"
                percentage={{
                  color: "success",
                  amount: "",
                  label: "último mes",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            {/* Gráfico de actividades */}
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                {activitiesChartType === "bar" ? (
                  <ReportsBarChart
                    color="info"
                    title="Total de actividades"
                    description="Total de actividades creadas el último semestre"
                    date={lastSemesterDateString}
                    chart={sales}
                  />
                ) : (
                  <ReportsLineChart
                    color="info"
                    title="Total de actividades"
                    description="Total de actividades creadas el último semestre"
                    date={lastSemesterDateString}
                    chart={sales}
                  />
                )}
              </MDBox>
              <MDBox mb={1.5}>
                <FormControl fullWidth>
                  <InputLabel id="activities-chart-type-label">Tipo de Gráfico</InputLabel>
                  <Select
                    labelId="activities-chart-type-label"
                    value={activitiesChartType}
                    onChange={(event) => setActivitiesChartType(event.target.value)}
                    label="Tipo de Gráfico"
                  >
                    <MenuItem value="bar">Gráfico de Barras</MenuItem>
                    <MenuItem value="line">Gráfico de Líneas</MenuItem>
                  </Select>
                </FormControl>
              </MDBox>
            </Grid>

            {/* Gráfico de evidencias */}
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                {evidencesChartType === "bar" ? (
                  <ReportsBarChart
                    color="success"
                    title="Total de evidencias"
                    description="Total de evidencias subidas el último semestre"
                    date={lastSemesterDateString}
                    chart={sales}
                  />
                ) : (
                  <ReportsLineChart
                    color="success"
                    title="Total de evidencias"
                    description="Total de evidencias subidas el último semestre"
                    date={lastSemesterDateString}
                    chart={sales}
                  />
                )}
              </MDBox>
              <MDBox mb={1.5}>
                <FormControl fullWidth>
                  <InputLabel id="evidences-chart-type-label">Tipo de Gráfico</InputLabel>
                  <Select
                    labelId="evidences-chart-type-label"
                    value={evidencesChartType}
                    onChange={(event) => setEvidencesChartType(event.target.value)}
                    label="Tipo de Gráfico"
                  >
                    <MenuItem value="bar">Gráfico de Barras</MenuItem>
                    <MenuItem value="line">Gráfico de Líneas</MenuItem>
                  </Select>
                </FormControl>
              </MDBox>
            </Grid>

            {/* Gráfico de participantes */}
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                {participantsChartType === "bar" ? (
                  <ReportsBarChart
                    color="dark"
                    title="Total de participantes"
                    description="Total de participantes registrados el último semestre"
                    date={lastSemesterDateString}
                    chart={tasks}
                  />
                ) : (
                  <ReportsLineChart
                    color="dark"
                    title="Total de participantes"
                    description="Total de participantes registrados el último semestre"
                    date={lastSemesterDateString}
                    chart={tasks}
                  />
                )}
              </MDBox>
              <MDBox mb={1.5}>
                <FormControl fullWidth>
                  <InputLabel id="participants-chart-type-label">Tipo de Gráfico</InputLabel>
                  <Select
                    labelId="participants-chart-type-label"
                    value={participantsChartType}
                    onChange={(event) => setParticipantsChartType(event.target.value)}
                    label="Tipo de Gráfico"
                  >
                    <MenuItem value="bar">Gráfico de Barras</MenuItem>
                    <MenuItem value="line">Gráfico de Líneas</MenuItem>
                  </Select>
                </FormControl>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              {/* <Projects /> */}
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {/* <OrdersOverview /> */}
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
