import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import PropTypes from "prop-types";
import { ParticipantUpdate } from "./ParticipantUpdate";
import { Pagination, Stack } from "@mui/material";

export default function ParticipantList({
  handleParticipantCreate,
  handleParticipantList = [], // Asegúrate de tener un valor predeterminado
  handleopenNewUpload,
  entriesStart,
  entriesEnd,
  totalData,
  totalPages,
  currentPage,
  handlePageChange,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentParticipant, setCurrentParticipant] = useState(null);
  const [participants, setParticipants] = useState(handleParticipantList);

  useEffect(() => {
    setParticipants(handleParticipantList);
  }, [handleParticipantList]);

  const handleOpenModal = (participant) => {
    setCurrentParticipant(participant);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setCurrentParticipant(null);
  };

  const updateParticipantInBackend = async (updatedParticipant, id) => {
    try {
      if (!id) {
        throw new Error("ID del participante no proporcionado.");
      }

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/participants/update/${id}/`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedParticipant),
        }
      );

      if (response.ok) {
        const result = await response.json();
        return { success: true, result: result };
      } else {
        const errorText = await response.text();
        throw new Error(`Error del servidor al realizar la solicitud: ${errorText}`);
      }
    } catch (error) {
      const message = "Error del servidor al realizar la solicitud";
      return { success: false, message: error.message || message };
    }
  };

  const handleUpdateParticipant = (updatedParticipant) => {
    if (currentParticipant) {
      updateParticipantInBackend(updatedParticipant, currentParticipant.id)
        .then((result) => {
          if (result.success) {
            const updatedParticipants = participants.map((participant) =>
              participant.id === result.result.id ? result.result : participant
            );
            setParticipants(updatedParticipants); // Actualiza el estado local
            handleParticipantList(updatedParticipants); // Actualiza el estado en el componente padre
            handleCloseModal(); // Cierra el modal después de una actualización exitosa
          } else {
            alert(result.message); // Muestra el mensaje de error al usuario
          }
        })
        .catch((error) => {});
    }
  };

  const columns = [
    { Header: "Documento", accessor: "document", align: "center" },
    { Header: "Nombre", accessor: "name", align: "center" },
    { Header: "Apellido", accessor: "last_name", align: "center" },
    { Header: "Estado", accessor: "state", align: "center" },
    { Header: "Género", accessor: "gender", align: "center" },
    { Header: "Ubicación Geográfica", accessor: "location", align: "center" },
    { Header: "Programa Académico", accessor: "academic_program", align: "center" },
    { Header: "Semestre", accessor: "semester", align: "center" },
    { Header: "Acciones", accessor: "action", width: "12%", align: "center" },
  ];

  const rows = participants.map((participant) => ({
    document: (
      <MDTypography variant="body2" style={{ fontSize: "14px", fontFamily: "Roboto, sans-serif" }}>
        {participant.document}
      </MDTypography>
    ),
    name: (
      <MDTypography variant="body2" style={{ fontSize: "14px", fontFamily: "Roboto, sans-serif" }}>
        {participant.name}
      </MDTypography>
    ),
    last_name: (
      <MDTypography variant="body2" style={{ fontSize: "14px", fontFamily: "Roboto, sans-serif" }}>
        {participant.last_name}
      </MDTypography>
    ),
    state: (
      <MDTypography variant="body2" style={{ fontSize: "14px", fontFamily: "Roboto, sans-serif" }}>
        {participant.state}
      </MDTypography>
    ),
    gender: (
      <MDTypography variant="body2" style={{ fontSize: "14px", fontFamily: "Roboto, sans-serif" }}>
        {participant.gender}
      </MDTypography>
    ),
    location: (
      <MDTypography variant="body2" style={{ fontSize: "14px", fontFamily: "Roboto, sans-serif" }}>
        {participant.location}
      </MDTypography>
    ),
    academic_program: (
      <MDTypography variant="body2" style={{ fontSize: "14px", fontFamily: "Roboto, sans-serif" }}>
        {participant.academic_program}
      </MDTypography>
    ),
    semester: (
      <MDTypography variant="body2" style={{ fontSize: "14px", fontFamily: "Roboto, sans-serif" }}>
        {participant.semester}
      </MDTypography>
    ),
    action: (
      <MDBox>
        <a onClick={() => handleOpenModal(participant)} style={{ cursor: "pointer" }}>
          <EditOutlinedIcon
            titleAccess="Editar"
            style={{
              fontSize: "24px",
              width: "24px",
              height: "24px",
              color: "#000000",
            }}
          />
        </a>
      </MDBox>
    ),
  }));

  return (
    <>
      <MDBox
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "10px", // Espacio entre los botones
          marginRight: "2vw",
          marginTop: "10px",
          marginBottom: "20px",
        }}
      >
        <MDButton onClick={handleParticipantCreate} variant="gradient" color="info">
          Crear
        </MDButton>
        <MDButton onClick={handleopenNewUpload} variant="gradient" color="info">
          Carga masiva
        </MDButton>
      </MDBox>
      <DataTable
        table={{ columns, rows }}
        isSorted={false}
        entriesPerPage={false}
        showTotalEntries={true}
        pagination={{ variant: "gradient", color: "info" }} // Estilo de paginación
        canSearch={false}
      />
      {!participants || participants.length === 0 ? (
        <MDBox style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <MDTypography
            variant="button"
            color="secondary"
            fontWeight="regular"
            style={{ fontSize: "20px", fontFamily: "Roboto, sans-serif" }}
          >
            No hay datos
          </MDTypography>
        </MDBox>
      ) : (
        <>
          {/* Mostrar la paginación */}
          {/* <MDBox style={{ marginLeft: "20px" }} mb={{ xs: 3, sm: 0 }}>
            <MDTypography
              style={{ left: "20px" }}
              variant="button"
              color="secondary"
              fontWeight="regular"
            >
              Mostrando {entriesStart} hasta {entriesEnd} de {totalData} resultados en total
            </MDTypography>
          </MDBox>
          <Stack style={{ marginLeft: "20px", marginBottom: "10px" }} spacing={2}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
            ></Pagination>
          </Stack>{" "} */}
        </>
      )}
      <ParticipantUpdate
        open={modalOpen}
        handleClose={handleCloseModal}
        onSubmit={handleUpdateParticipant}
        participant={currentParticipant}
      />
    </>
  );
}

ParticipantList.propTypes = {
  handleParticipantCreate: PropTypes.func.isRequired,
  handleParticipantList: PropTypes.arrayOf(PropTypes.object),
  handleopenNewUpload: PropTypes.func.isRequired,
};
