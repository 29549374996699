import { useEffect, useState } from "react";
import { Box, Divider, Modal, Card, Icon, Typography } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import PropTypes from "prop-types";

export function RoleCreate({ open, handleClose, getFieldValue, handleFieldChange, handleSubmit }) {
  const [isDisabled, setIsDisabled] = useState(true);

  // Monitorear cambios en el input y actualizar el estado del botón
  useEffect(() => {
    const fieldValue = getFieldValue("name") || ""; // Asegurarse de que no sea undefined
    setIsDisabled(!fieldValue.trim()); // Deshabilita si está vacío o contiene solo espacios
  }, [getFieldValue]);

  const handleInputChange = (fieldName, value) => {
    // Eliminar espacios al principio y al final
    handleFieldChange(fieldName, value.trimStart());
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "400px",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <MDBox
          mt={-7}
          color="white"
          bgColor="info"
          variant="gradient"
          borderRadius="lg"
          p={2}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Typography id="modal-modal-title" component="h2">
            Crear rol
          </Typography>
          <Icon
            sx={{
              fontSize: "2em",
              stroke: "currentColor",
              strokeWidth: "2px",
              cursor: "pointer",
              marginLeft: "auto",
            }}
            onClick={handleClose}
          >
            close
          </Icon>
        </MDBox>
        <Divider variant="middle" />
        <form onSubmit={handleSubmit}>
          <Box>
            <MDBox
              style={{
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
              }}
            >
              <MDBox style={{ width: "100%" }}>
                <MDInput
                  style={{ width: "100%" }}
                  label="Nombre"
                  value={getFieldValue("name")}
                  onChange={(e) => handleInputChange("name", e.target.value)}
                />
              </MDBox>
            </MDBox>
          </Box>
          <Divider variant="middle" />
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <MDButton
              color="error"
              sx={{
                flex: 1,
                mr: 1,
              }}
              onClick={handleClose}
            >
              Cancelar
            </MDButton>
            <MDButton
              type="submit"
              disabled={isDisabled}
              color="success"
              sx={{
                flex: 1,
                ml: 1,
              }}
            >
              Guardar
            </MDButton>
          </Box>
        </form>
      </Card>
    </Modal>
  );
}

RoleCreate.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  getFieldValue: PropTypes.func,
  handleFieldChange: PropTypes.func,
  handleSubmit: PropTypes.func,
};
