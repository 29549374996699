/* 
██╗██╗░░░██╗  ██████╗░██╗░██████╗░██╗████████╗░█████╗░██╗░░░░░
██║██║░░░██║  ██╔══██╗██║██╔════╝░██║╚══██╔══╝██╔══██╗██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░██╗░██║░░░██║░░░███████║██║░░░░░
██║██║░░░██║  ██║░░██║██║██║░░╚██╗██║░░░██║░░░██╔══██║██║░░░░░
██║╚██████╔╝  ██████╔╝██║╚██████╔╝██║░░░██║░░░██║░░██║███████╗
╚═╝░╚═════╝░  ╚═════╝░╚═╝░╚═════╝░╚═╝░░░╚═╝░░░╚═╝░░╚═╝╚══════╝
╔════════════════════════════════════════════════════════════╗
║              EQUIPO DE DESARROLLO DE SOFTWARE              ║
║                   MODULO DESARROLLADO POR                  ║
╟┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄┄╢
║ Juan Esteban Atehortua Sanchez                             ║
║ juan.atehortua@iudigital.edu.co                            ║
╚════════════════════════════════════════════════════════════╝
*/

import { useEffect, useState, useRef } from "react";
import {
  RoleCreateService,
  RoleDeleteService,
  RoleListService,
  RoleUpdateService,
  PermissionListService,
  createSource,
  // RoleDeleteService,
  // RoleByIDService,
  // RolePermisionsListService,
} from "../services/RoleService";
import RoleList from "../modules/RoleList";
import { RoleCreate } from "../modules/RoleCreate";
import { RoleEdit } from "../modules/RoleEdit";
import { RoleDelete } from "../modules/RoleDelete";
// import FilterController from "components/filter/controller/FilterController";
import Spinner from "components/MDSpinner";
import MDAlert from "components/Alert";
import { useNavigate } from "react-router-dom";
import FilterController from "components/filter/controller/FilterController";

export default function RoleController() {
  // Common Controllers
  const [isDisabled, setIsDisabled] = useState(false);
  const [defaultData, setDefaultData] = useState({});
  const [isFormModified, setIsFormModified] = useState(false);
  const [recoveredData, setRecoveredData] = useState({});
  const [editedData, setEditedData] = useState({});
  const [newData, setNewData] = useState({});
  const [buildData, setBuildData] = useState({});
  const [loading, setLoading] = useState(false);
  const [typeAlert, setTypeAlert] = useState("");
  const [message, setMessage] = useState("");
  const [isAlert, setIsAlert] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const header = [{}];
  const filterControllerRef = useRef();

  // const dataFilter = useState([
  //   {
  //     field: "NOMBRE",
  //     value: "name",
  //   },
  // ]);

  // Configuration of filter types dynamically
  const filterConfig = [
    { visibleName: "Nombre", dbField: "name", type: "text" },
    { visibleName: "Fecha de Creación", dbField: "created_at", type: "date" },
    { visibleName: "Fecha de Actualización", dbField: "updated_at", type: "date" },
  ];

  const dataTable = "roles"; //Tabla indicada
  const navigate = useNavigate();

  // Field Check

  const getFieldValueNew = (fieldName) => {
    return newData[fieldName];
  };

  const getFieldValue = (fieldName) => {
    return editedData[fieldName];
  };

  const handleFieldChangeNew = (fieldName, value) => {
    setNewData({
      ...newData,
      [fieldName]: value,
    });
  };

  const handleFieldChange = (fieldName, value) => {
    setEditedData({
      ...editedData,
      [fieldName]: value,
    });
  };

  // ByID Controllers

  const requestRoleByID = async (idRole) => {
    try {
      const role = await RoleByIDService(idRole);
      const { payload } = role;
      return { payload };
    } catch (error) {
      console.error(error);
    }
  };

  // Permit List Controllers

  const [handleRolePermitList, setRolePermitList] = useState();

  const requestRolePermitList = async () => {
    const result = await RolePermisionsListService();
    if (result && result.payload) {
      const { payload } = result;
      const rolePermitsArray = Object.entries(payload);

      setRolePermitList(rolePermitsArray);
    }
  };

  // useEffect(() => {
  //   requestRolePermitList();
  // }, []);

  // List Controllers

  const [handleRoleList, setRoleList] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesStart, setEntriesStart] = useState(0);
  const [entriesEnd, setEntriesEnd] = useState(0);
  const [paginaSize, setPaginaSize] = useState(10);

  const requestRoleList = async (pageIndex) => {
    const result = await RoleListService(pageIndex);
    if (result.success) {
      // const { payload } = result;
      await setRoleList(result.payload);
      // await setTotalPages(payload.last_page);
      // await setTotalData(payload.total);
      // await setEntriesStart(payload.from);
      // await setEntriesEnd(payload.to);
    } else {
      handleAlert("fail", result.message);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const pageIndexAndPageSize = async (pageSize) => {
    await setPaginaSize(pageSize);
    requestRoleList(currentPage === 1 ? currentPage : (currentPage - 1) * pageSize + 1);
  };

  useEffect(() => {
    requestRoleList(currentPage === 1 ? currentPage : (currentPage - 1) * paginaSize + 1);
  }, [currentPage]);

  const requestPermissions = async () => {
    const result = await PermissionListService();
    if (result.success) {
      setPermissions(result.payload.payload);
    } else {
      handleAlert("fail", result.message);
    }
  };

  // Create Controllers

  const [openCreate, setOpenCreate] = useState(false);

  const handleRoleCreate = (event) => {
    event.preventDefault();
    setIsDisabled(false);
    const data = {
      name: "",
      description: "",
      //permissions: "",
    };
    setDefaultData(data);
    setNewData(data);
    setOpenCreate(true);
  };

  const submitRoleCreate = async (e) => {
    e.preventDefault();
    setLoading(true);
    const convertedObject = convertValuesToString(newData);
    const { description, ...newDataWithoutDescription } = newData;
    const softwareId = localStorage.getItem("software_id");
    const softwareIdWithoutHyphens = softwareId.replace(/-/g, "");
    newDataWithoutDescription["software_id"] = softwareIdWithoutHyphens;
    const result = await RoleCreateService(newDataWithoutDescription);

    if (result.success) {
      await requestRoleList(1);
      // Redirigir al usuario a la página de edición del rol creado
      navigate(`/edit-role/${result.payload.id}`);
      //Mandar mensaje de exito para renderizar en la alerta
      handleAlert("success", "El rol se ha creado correctamente");
    } else {
      //Mandar mensaje de error para renderizar en la alerta
      handleAlert("fail", result.message);
    }
    callHandleClickSubmit();
    closeRoleCreate();
    setLoading(false);
  };

  const closeRoleCreate = () => setOpenCreate(false);

  // Edit Controllers

  const [openEdit, setOpenEdit] = useState(false);

  const handleRoleEdit = async (event) => {
    event.preventDefault();
    setIsDisabled(event.currentTarget.dataset.id === "view" ? true : false);
    // const role = await requestRoleByID(event.currentTarget.dataset.id);
    const { dataset } = event.currentTarget;
    const roleId = dataset.id;
    const data = {
      id: dataset.id,
      name: dataset.name,
      software_id: dataset.softwareId,
    };
    navigate(`/edit-role/${roleId}`);
    /* await requestPermissions();
    setRecoveredData(data);
    setEditedData(data);
    setOpenEdit(true); */
  };

  const submitRoleEdit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const convertedObject = convertValuesToString(editedData);
    const result = await RoleUpdateService(editedData.id, editedData);
    if (result.success) {
      await requestRoleList(1);
      //Mandar mensaje de exito para renderizar en la alerta
      handleAlert("success", "El rol se ha actualizado correctamente");
    } else {
      //Mandar mensaje de error para renderizar en la alerta
      handleAlert("fail", result.message);
    }
    callHandleClickSubmit();
    closeRoleEdit();
    setLoading(false);
  };

  const closeRoleEdit = () => setOpenEdit(false);

  // Delete Controllers

  const [openDelete, setOpenDelete] = useState(false);
  const [infoDelete, setInfoDelete] = useState({});

  const requestRoleDelete = async (id) => {
    try {
      const result = await RoleDeleteService(id);
      if (result.success) {
        requestRoleList(1);
        handleAlert("success", "El rol se ha eliminado correctamente");
      } else {
        handleAlert("fail", result.message);
      }
    } catch (error) {
      handleAlert("fail", error.message || "Error al eliminar el rol");
      console.error(error);
    }
  };

  const handleRoleDelete = (event) => {
    event.preventDefault();

    const { dataset } = event.currentTarget;
    const data = {
      id: dataset.id,
      name: dataset.name,
      //permissions: dataset.permissions,
    };
    setInfoDelete(data);
    setOpenDelete(true);
  };

  const submitRoleDelete = async (e) => {
    e.preventDefault();
    setLoading(true);
    convertValuesToString(infoDelete.id);
    requestRoleDelete(infoDelete.id);
    callHandleClickSubmit();
    closeRoleDelete();
    setLoading(false);
  };

  const closeRoleDelete = () => setOpenDelete(false);

  // Test Keys

  // useEffect(() => {
  //   const hasFormChanged = Object.keys(editedData).some(
  //     (fieldName) => editedData[fieldName] !== recoveredData[fieldName]
  //   );

  //   const isAnyFieldEmpty = Object.values(editedData).some((value) => value === "");

  //   setIsFormModified(!isAnyFieldEmpty && hasFormChanged);
  // }, [editedData]);

  useEffect(() => {
    const requiredFields = ["name", "description"];
    const someFieldIsEmpty = requiredFields.some((fieldName) => !newData[fieldName]);
    setIsFormModified(someFieldIsEmpty);
  }, [newData]);

  // Stringify

  function convertValuesToString(objeto) {
    const convertedObject = {};

    for (const clave in objeto) {
      if (objeto.hasOwnProperty(clave)) {
        convertedObject[clave] = String(objeto[clave]);
      }
    }

    return convertedObject;
  }

  // Función para llamar a handleClickSubmit desde el componente padre
  const callHandleClickSubmit = () => {
    if (filterControllerRef.current) {
      filterControllerRef.current.handleClickSubmit();
    }
  };

  // Maneja las alertas de exito fallo
  const handleAlert = (typeAlert, message) => {
    setTypeAlert(typeAlert);
    setMessage(message);
    setIsAlert(true);
  };

  return (
    <>
      <Spinner loading={loading}></Spinner>
      <MDAlert
        isAlert={isAlert}
        setIsAlert={setIsAlert}
        typeAlert={typeAlert}
        message={message}
      ></MDAlert>
      {/* <FilterController
        filterConfig={filterConfig}
        dataTable={dataTable}
        setTotalData={setTotalData}
        setEntriesEnd={setEntriesEnd}
        setEntriesStart={setEntriesStart}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setTotalPages={setTotalPages}
        setListData={setRoleList}
        handleAlert={handleAlert}
      ></FilterController> */}
      {
        <RoleList
          handleRoleList={handleRoleList}
          handleRoleCreate={handleRoleCreate}
          handleRoleEdit={handleRoleEdit}
          handleRoleDelete={handleRoleDelete}
          pageIndexAndPageSize={pageIndexAndPageSize}
          entriesStart={entriesStart}
          entriesEnd={entriesEnd}
          totalData={totalData}
          totalPages={totalPages}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      }

      <RoleCreate
        open={openCreate}
        handleClose={closeRoleCreate}
        isFormModified={isFormModified}
        isDisabled={isDisabled}
        getFieldValue={getFieldValueNew}
        handleFieldChange={handleFieldChangeNew}
        handleSubmit={submitRoleCreate}
        handleRolePermitList={handleRolePermitList}
      />

      <RoleEdit
        open={openEdit}
        handleClose={closeRoleEdit}
        isFormModified={isFormModified}
        isDisabled={isDisabled}
        getFieldValue={getFieldValue}
        handleFieldChange={handleFieldChange}
        handleSubmit={submitRoleEdit}
        handleRolePermitList={permissions}
      />

      {
        <RoleDelete
          openDelete={openDelete}
          closeDelete={closeRoleDelete}
          infoDelete={infoDelete}
          submitDelete={submitRoleDelete}
        />
      }
    </>
  );
}
