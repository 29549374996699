import React, { useState } from "react";
import {
  Box,
  Divider,
  Icon,
  Modal,
  Typography,
  Card,
  TextField,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
  CircularProgress,
  // Skeleton,
  // Stack,
  // LinearProgress,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import SearchIcon from "@mui/icons-material/Search";
import { fetchParticipantByDocument } from "../services/ParticipantService";

export function ParticipantCreate({ open, handleClose, onSubmit }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "400px",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
  };

  const values = {
    document: "",
    name: "",
    last_name: "",
    state: "",
    gender: "",
    location: "",
    academic_program: "",
    semester: "",
  };

  const [formValues, setFormValues] = useState(values);
  const [errors, setErrors] = useState(values);
  const [isFetchParticipantData, setIsFetchParticipantData] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [loading, setLoading] = useState(false);

  const validationFields = (fieldName, value) => {
    let isValidField = true;
    let newErrors = {};
    if (value == undefined || value == null || value == "") {
      isValidField = false;
      newErrors[fieldName] = {
        message: "El campo es requerido",
        error: true,
      };
    } else if (fieldName == "document") {
      if (!/^\d+$/.test(value)) {
        isValidField = false;
        newErrors[fieldName] = {
          message: "El campo debe ser númerico",
          error: true,
        };
      }
    }
    setErrors(newErrors);
    return isValidField;
  };

  const capitalizeFirstLetter = (input) => {
    let palabras = input.split(" "); // Dividir el texto en palabras
    for (let a = 0; a < palabras.length; a++) {
      let letra = palabras[a].charAt(0).toUpperCase(); // Capitalizar la primera letra
      palabras[a] = letra + palabras[a].substring(1).toLowerCase(); // Añadir el resto de la palabra en minúscula
    }
    return palabras.join(" "); // Unir las palabras de nuevo
  };

  const handleChange = (event, type) => {
    const { name, value } = event.target;
    setIsFetchParticipantData(false);
    validationFields(name, value);
    setFormValues({
      ...formValues,
      [name]: type != "select" ? capitalizeFirstLetter(value) : value,
    });
  };

  // Función para obtener los datos del participante
  const fetchParticipantData = async () => {
    const { document } = formValues;
    const result = await fetchParticipantByDocument(document);

    if (result.success) {
      const participantData = result.data; // Asegúrate de que esto corresponde a cómo tu API devuelve los datos
      if (!participantData) {
        setErrors({
          document: { message: "Participante no encontrado", error: true },
        });
        return;
      }
      setFormValues((prev) => ({
        ...prev, // Mantén los valores previos para los campos no autocompletados
        name: participantData.name || "",
        last_name: participantData.lastname || "",
        state: participantData.card_types || "", // Asumiendo que 'card_types' es lo que quieres para 'state'
        from_credencials: true,
      }));
      setErrors({}); // Limpia cualquier error previo
      setIsFetchParticipantData(true);
      setIsReadOnly(true);
    } else {
      setErrors({
        document: { message: result.message || "Participante no encontrado", error: true },
      });
      setIsFetchParticipantData(false);
    }
  };

  const handleSearchClick = async () => {
    setLoading(true);
    if (!formValues.document) {
      setErrors({
        document: { message: "Por favor ingrese un documento válido", error: true },
      });
      setLoading(false);
      return;
    }
    if (isFetchParticipantData) {
      setLoading(false);
      return;
    }
    await fetchParticipantData();
    setLoading(false);
  };

  const handleClosed = () => {
    setFormValues(values);
    handleClose();
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    for (let formValue in formValues) {
      const isValidField = validationFields(formValue, formValues[formValue]);
      if (!isValidField) return;
    }
    if (
      errors &&
      (!errors.document?.error ||
        !errors.name?.error ||
        !errors.last_name?.error ||
        !errors.academic_program?.error ||
        !errors.gender?.error ||
        !errors.location?.error ||
        !errors.state?.error ||
        !errors.semester?.error)
    ) {
      onSubmit(formValues); // Llama al controlador para manejar el submit
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ overflow: "auto" }}
    >
      <Card sx={style}>
        <MDBox
          mt={-7}
          color="white"
          bgColor="info"
          variant="gradient"
          borderRadius="lg"
          p={2}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Typography id="modal-modal-title" component="h2">
            Crear Partcipante
          </Typography>
          <Icon
            sx={{
              fontSize: "2em",
              stroke: "currentColor",
              strokeWidth: "2px",
              cursor: "pointer",
              marginLeft: "auto",
            }}
            onClick={handleClosed}
          >
            close
          </Icon>
        </MDBox>
        <Divider variant="middle" />
        <Box
          autoComplete="off"
          autoCapitalize="on"
          component="form"
          onSubmit={handleFormSubmit}
          sx={{ mt: 2 }}
        >
          <TextField
            fullWidth
            label="Cédula o Identificación"
            variant="outlined"
            name="document"
            value={formValues.document}
            onChange={handleChange}
            error={errors && errors.document?.error}
            helperText={errors && errors.document?.message}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSearchClick}>
                    {loading ? <CircularProgress size={24} /> : <SearchIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ mb: 2 }}
          />
          <>
            {/* {loading && (
              <Box sx={{ width: "100%", boxSizing: "border-box", mb: 2 }}>
                <LinearProgress color="secondary" sx={{ width: "100%" }} />
              </Box>
            )} */}
            <TextField
              fullWidth
              label="Nombre"
              variant="outlined"
              name="name"
              value={formValues.name}
              onChange={handleChange}
              error={errors && errors.name?.error} // Muestra error si hay mensaje de error
              helperText={errors && errors.name?.message} // Muestra el mensaje de error
              disabled={isReadOnly}
              sx={{ mb: 2 }}
            />
            <TextField
              autoComplete="off"
              fullWidth
              label="Apellido"
              variant="outlined"
              name="last_name"
              value={formValues.last_name}
              onChange={handleChange}
              error={errors && errors.last_name?.error} // Muestra error si hay mensaje de error
              helperText={errors && errors.last_name?.message} // Muestra el mensaje de error
              disabled={isReadOnly}
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Estado"
              name="state"
              value={formValues.state}
              onChange={handleChange}
              error={errors.state?.error}
              helperText={errors.state?.message}
              disabled={isReadOnly}
              sx={{ mb: 2 }}
            />
          </>
          <FormControl
            sx={{ mb: 2 }}
            fullWidth
            error={errors && errors.gender?.error} // Muestra error si hay mensaje de error
          >
            <InputLabel id="select-gender-label">Género</InputLabel>
            <Select
              labelId="select-gender-label"
              id="select-gender"
              value={formValues.gender}
              label="Género"
              name="gender"
              onChange={(event) => handleChange(event, "select")}
              sx={{ minHeight: 44 }} // Muestra el mensaje de error
            >
              <MenuItem value="Femenino">Femenino</MenuItem>
              <MenuItem value="Masculino">Masculino</MenuItem>
              <MenuItem value="Otro">Otro</MenuItem>
            </Select>
            <FormHelperText>{errors && errors.gender?.message}</FormHelperText>
          </FormControl>
          <TextField
            fullWidth
            label="Ubicación Geográfica"
            variant="outlined"
            name="location"
            value={formValues.location}
            onChange={handleChange}
            error={errors && errors.location?.error} // Muestra error si hay mensaje de error
            helperText={errors && errors.location?.message} // Muestra el mensaje de error
            sx={{ mb: 2 }}
          />
          <TextField
            autoComplete="off"
            fullWidth
            label="Programa Académico"
            variant="outlined"
            name="academic_program"
            value={formValues.academic_program}
            onChange={handleChange}
            error={errors && errors.academic_program?.error} // Muestra error si hay mensaje de error
            helperText={errors && errors.academic_program?.message} // Muestra el mensaje de error
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Semestre"
            variant="outlined"
            name="semester"
            value={formValues.semester}
            onChange={handleChange}
            error={errors && errors.semester?.error} // Muestra error si hay mensaje de error
            helperText={errors && errors.semester?.message} // Muestra el mensaje de error
            sx={{ mb: 2 }}
          />
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
            <MDButton
              color="error"
              sx={{
                flex: 1,
                mr: 1,
              }}
              onClick={handleClosed}
            >
              Cancelar
            </MDButton>
            <MDButton
              type="submit"
              color="success"
              sx={{
                flex: 1,
                ml: 1,
              }}
            >
              Guardar
            </MDButton>
          </Box>
        </Box>
      </Card>
    </Modal>
  );
}
