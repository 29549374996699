import React from "react";
import { Pagination, Stack } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import PropTypes from "prop-types";
import usePermission from "hooks/usePermission";

export default function RoleList({
  handleRoleCreate,
  handleRoleList,
  handleRoleEdit,
  handleRoleDelete,
  pageIndexAndPageSize,
  entriesStart,
  entriesEnd,
  totalData,
  totalPages,
  currentPage,
  handlePageChange,
  handleOpenNew,
}) {
  // Verificar permisos
  const canCreate = usePermission("Crear", "Roles");
  const canEdit = usePermission("Editar", "Roles");
  const canDelete = usePermission("Eliminar", "Roles");

  return (
    <>
      <MDBox
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "2vw",
          marginTop: "10px",
          marginBottom: "20px",
        }}
      >
        {canCreate && (
          <MDButton onClick={handleRoleCreate} variant="gradient" color="info">
            Crear
          </MDButton>
        )}
      </MDBox>
      <DataTable
        table={{
          columns: [
            { Header: "name", accessor: "name", align: "center" },
            { Header: "acciones", accessor: "action", width: "12%", align: "center" },
          ],
          rows: handleRoleList
            ? handleRoleList.map((role) => ({
                id: (
                  <MDTypography
                    component="a"
                    href="#"
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                  >
                    {role.id}
                  </MDTypography>
                ),
                name: (
                  <MDTypography
                    component="a"
                    href="#"
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                  >
                    {role.name}
                  </MDTypography>
                ),
                software_id: (
                  <MDTypography
                    component="a"
                    href="#"
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                  >
                    {role.software_id}
                  </MDTypography>
                ),
                action: (
                  <>
                    {canDelete && (
                      <a
                        data-id={role.id}
                        data-name={role.name}
                        data-softwareid={role.software_id}
                        onClick={handleRoleDelete}
                        style={{ marginLeft: "8px", cursor: "pointer" }}
                      >
                        <DeleteForeverOutlinedIcon
                          titleAccess="Eliminar"
                          style={{
                            fontSize: "200px",
                            width: "24px",
                            height: "24px",
                            backgroundImage: "#000000",
                          }}
                        />
                      </a>
                    )}
                    {canEdit && (
                      <a
                        data-id={role.id}
                        data-name={role.name}
                        data-softwareId={role.software_id}
                        data-description={role.description}
                        onClick={handleRoleEdit}
                        style={{ marginLeft: "8px", cursor: "pointer" }}
                      >
                        <EditOutlinedIcon
                          titleAccess="Editar"
                          style={{
                            fontSize: "200px",
                            width: "24px",
                            height: "24px",
                            backgroundImage: "#000000",
                          }}
                        />
                      </a>
                    )}
                  </>
                ),
              }))
            : [],
        }}
        canSearch={false}
        isSorted={false}
        entriesPerPage={false}
        showTotalEntries={true}
        pageIndexAndPageSize={pageIndexAndPageSize}
        pagination={{ variant: "gradient", color: "info" }} // Estilo de paginación
      />

      {!handleRoleList ? (
        <MDBox style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <MDTypography
            style={{ left: "20px", fontSize: "20px" }}
            variant="button"
            color="secondary"
            fontWeight="regular"
          >
            No hay datos
          </MDTypography>
        </MDBox>
      ) : (
        <>
          {/* Mostrar la paginación */}
          {/* <MDBox style={{ marginLeft: "20px" }} mb={{ xs: 3, sm: 0 }}>
            <MDTypography
              style={{ left: "20px" }}
              variant="button"
              color="secondary"
              fontWeight="regular"
            >
              Mostrando {entriesStart} hasta {entriesEnd} de {totalData} resultados en total
            </MDTypography>
          </MDBox>
          <Stack style={{ marginLeft: "20px", marginBottom: "10px" }} spacing={2}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
            ></Pagination>
          </Stack>{" "} */}
        </>
      )}
    </>
  );
}

RoleList.propTypes = {
  handleRoleCreate: PropTypes.func,
  handleRoleList: PropTypes.array,
  handleRoleDelete: PropTypes.func,
  pageIndexAndPageSize: PropTypes.func,
  entriesStart: PropTypes.number,
  entriesEnd: PropTypes.number,
  totalData: PropTypes.number,
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  handlePageChange: PropTypes.func,
  handleRoleEdit: PropTypes.func,
};
